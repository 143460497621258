import Section from '@components/Section';
import Container from '@components/UI/Container';
import Icon from '@components/UI/Icon';
import VideoModal from '@components/VideoModal';

import css from '@utils/css';

import React from 'react';


const HomeBlockquote = () => (
    <Section
        mods={ { home: true } }
    >
        <Container>
            <blockquote className={ css('flex flex-col gap-4 justify-center text-center max-w-[996px] mx-auto') }>
                <div className='flex justify-center'>
                    <Icon
                        name='quote-classic'
                        color={ 'violet-300' }
                        size={ 78 }
                        className='text-center'
                    />
                </div>
                <p
                    className={ css('text-24p font-[700] leading-40p 1024:(text-40p leading-56p -tracking-[1px])') }
                >
                    “Traqq provides <span className={'text-violet-700'}>detailed reports</span> and <span className={'text-violet-700'}>diverse analytics</span>. It's helpful to see data on user productivity and consolidated team productivity."
                </p>
                <footer
                    className='text-20p leading-32p text-neutral-700'
                >
                    Lauren H. — AR Account Manager
                </footer>
            </blockquote>
            <div className='flex justify-center mt-12'>
                <VideoModal
                    videoUrl={'https://www.youtube.com/embed/SNFKyh62lJo?si=XKjwUwtnin6Vsidk&autoplay=1'}
                    className={'button button_lg button_link button_video button_animate'}
                    btnText={'How does it work?'}
                    iconSize={ 32 }
                />
            </div>
        </Container>
    </Section>
);

export default HomeBlockquote;
